.wrapper {
  width: 90%;
  margin: 2rem auto;
  background-color: #fff;

  @media (min-width:700px) {
    width: 50%;
  }
  @media (min-width:1700px) {
    width: 40%;
    margin: 8rem auto;
  }
}

.title {
  font-weight: 300;
  text-align: center;
  opacity: 0;
  transform: translateY(60px);

  &Span {
    color: #029EF5;
  }

  @media (min-width:1200px) {
    font-size: 1.2rem;
  }
}

.logos {
  padding: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &Img {
    width: 33%;
    padding: .5rem 1rem;
    transform: translateY(30px) scale(0);
    transform-origin: bottom;
    opacity: 0;

    @media (min-width:700px) {
      width: 25%;
    }
    @media (min-width:1200px) {
      width: 22%;
    }
    @media (min-width:1700px) {
      width: 18%;
    }
  }
}