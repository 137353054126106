.wrapper {
  width: 100%;
  padding: 2rem 0;
  margin: 0 auto;
  overflow: hidden;

  @media (min-width:700px) {
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows:  1fr 1fr;
    grid-template-areas: 
    "vps hosting"
    "vps domain";
    grid-gap: 3rem;
    align-items: center;
  }
  @media (min-width:1200px) {
    padding: 0;
  }
  @media (min-width:1700px) {
    width: 100%;
  }
}

.divProductHosting,
.divProductVPS,
.divProductDomain {
  transform: translateY(50px) scale(.95);
  margin: 0 0 2.5rem 0;

  @media (min-width:1200px) {
    margin: 2rem 2rem 2.5rem 2rem;
  }
}

.divProductHosting {
  grid-area: hosting;
}
.divProductVPS {
  grid-area: vps;
}
.divProductDomain {
  grid-area: domain;
}

.img{
  position: relative;
  width: 100%;
  cursor: pointer;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);

  &Product {
    display: block;
  }

  .hiddenBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #029EF5;
    padding: 1.5rem;
    transform: scale(0);
    transform-origin: bottom left;

    @media (min-width:1700px) {
      padding: 2.5rem;
    }
  }

  .showBox {
    transform: scale(1);
  }

  .text {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 300;

    @media (min-width:1700px) {
      font-size: 1.4rem;
    }
  }

  &Product {
    width: 100%;
  }

  .link {
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    margin-top: 1rem;
    z-index: 5;

    @media (min-width:1700px) {
      margin-top: 2rem;
      font-size: 1.4rem;
    }

    &::before {
      position: absolute;
      content: '';
      bottom: 4px;
      left: 0;
      width: 100%;
      height: 2px;
      background: rgba(9,203,222, .8);
      z-index: -1;
      transition: .4s;
    }
    &:hover::before {
      height: 8px;
    }
  }
}

.underTitle {
  font-size: 1.2rem;
  padding: .1rem .5rem;
  color: #029EF5;
  text-transform: uppercase;

  @media (min-width:1700px) {
    font-size: 1.4rem;
    font-weight: 900;
    letter-spacing: 1px;
    padding: .3rem .5rem;
  }
}