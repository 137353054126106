.wrapper {
  width: 100%;
  background-color: #0A0A0A;
  color: #fff;

  .widthSize {
    width: 100%;

    @media (min-width:1200px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas: 
      "contact ."
      "address copyright";
      align-items: center;
      padding: 2rem 8rem;
    }
    @media (min-width:1700px) {
      width: 60%;
      padding: 2rem 0;
      margin: 0 auto;
    }
  }
}

.titleSpan {
  color: #029EF5;
}

.contact {
  padding: 2rem 1rem 1rem 1rem;
  grid-area: contact;

  .write {
    font-weight: 900;
    letter-spacing: 1px;

    @media (min-width:1200px) {
      font-size: 2.1rem;
    }
  }

  .email {
    position: relative;
    font-weight: 300;
    letter-spacing: 1px;
    color: #fff;
    text-decoration: none;
    z-index: 5;

    @media (min-width:1200px) {
      font-size: 1.3rem;
    }

    &::before {
      position: absolute;
      content: '';
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 2px;
      background: rgba(9,203,222, .8);
      z-index: -1;
      transition: .4s;
    }
    &:hover::before {
      height: 10px;
    }
  }

  .callToActionWrapper {
    width: 100%;
    padding: 2rem 0 1rem;

    .callToActionBtn {
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      padding: .5rem 2.6rem;
      background: #029EF5;
      color: #ffffff;
      text-decoration: none;
      transition: .4s;

      &:hover {
        background: #ffffff;
        color: #029EF5;
      }

      @media (min-width:1200px) {
        padding: .7rem 4.3rem;
      }
    }
  }
}


.address {
  padding: 1rem 1rem 2rem;
  grid-area: address;

  @media (min-width:1200px) {
    font-size: 1.3rem;
  }

  .phone {
    font-weight: 300;
    color: #fff;
    text-decoration: none;
  }
}

.copyright {
  grid-area: copyright;
  padding: 1.5rem;
  font-weight: 300;
  font-size: .8rem;
  text-align: center;

  @media (min-width:1200px) {
    align-self: end;
  }

  .blusoft {
    color: #fff;
    text-decoration: none;
  }

  .frond {
    margin-top: .3rem;
  }
}